import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Remartastyle Sport Pub
			</title>
			<meta name={"description"} content={"Трансляції найпопулярніших спортивних подій, найпопулярніші закуски та найбільший асортимент пива в місті. Проведи вечір незабутньо."} />
			<meta property={"og:title"} content={"Remartastyle Sport Pub"} />
			<meta property={"og:description"} content={"Трансляції найпопулярніших спортивних подій, найпопулярніші закуски та найбільший асортимент пива в місті. Проведи вечір незабутньо."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span
						color="--orange"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Remartastyle
					</Span>
					{" "}
					<br />
					Sport Pub
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Трансляції найпопулярніших спортивних подій, найпопулярніші закуски та найбільший асортимент пива в місті. Проведи вечір незабутньо.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-orange"
						hover-background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Забронювати
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/live-sports-broadcasts.jpg?v=2023-10-05T13:29:55.124Z) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Ласкаво просимо!
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257">
					Не пропустіть можливості насолодитися найкращими умовами і смачною кухнею у Remartastyle Sport Pub. Приєднуйтеся до нас сьогодні та вибирайте найкраще!{"\n\n"}
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Найкращий вибір напоїв. Широкий асортимент алкогольних та безалкогольних напоїв. Більше 30 сортів пива.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Привітний персонал та запишна атмосфера в центрі міста.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						{"\n"}Спеціальні пропозиції такі як "Рибний день", "Чемпіонат світу", "Пивна п'ятниця" та інші.
					</Text>
				</Box>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
					Наша команда завжди готова привітати вас і зробити ваше перебування у закладі незабутнім.  Приходьте до нас і насолоджуйтеся унікальним вайбом разом з нами.{" "}
					<br />
					Ми завжди раді вас бачити!{"\n\n"}
				</Text>
			</Box>
			<Box
				display="flex"
				padding="0px 0px 0px 30px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
				height="550px"
				min-width="100px"
			>
				<Image
					src="https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14:03:19.348Z"
					display="block"
					border-radius="25px"
					srcSet="https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/interior-1.jpg?v=2023-10-05T14%3A03%3A19.348Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Photos />
		<Components.Footer />
	</Theme>;
});